<template>
  <v-expansion-panel class="mt-3">
    <v-expansion-panel-header>
      <span class="card-title">First load</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-form ref="form" v-model="valid" lazy-validation style="background-color: none">
            <v-row>
              <v-col cols="2">
                <mf-select
                  v-model="importConfiguration.selectedScale"
                  item-value="value"
                  item-text="text"
                  label="Escala"
                  :disabled="!editable"
                  :items="scales"
                ></mf-select>
              </v-col>
              <v-col cols="2">
                <mf-number-input v-model="importConfiguration.scaleAmount" label="Quantidade da escala" :disabled="!editable"></mf-number-input>
              </v-col>
              <v-col cols="3">
                <date-picker
                  :disabled="!editable"
                  label="Data de início"
                  :hide-details="false"
                  :rules="[validateDate]"
                  :date.sync="importConfiguration.first_load.start_date"
                  outline
                />
              </v-col>
              <v-col cols="3">
                <date-picker
                  :min="importConfiguration.first_load.start_date"
                  :max="maxFirstLoadEndDate"
                  :disabled="!editable"
                  label="Data final"
                  :hide-details="false"
                  :rules="[validateDate]"
                  :date.sync="importConfiguration.first_load.end_date"
                  outline
                />
              </v-col>
              <v-col cols="2">
                <mf-select v-model="importConfiguration.first_load.parallelism" label="Paralelismo" :disabled="!editable" :items="[1, 2, 3]"></mf-select>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <btn-card-actions class="pa-0 ml-0" :editable.sync="editable" :saving="saving" @save="saveManager" @cancel="cancel" />
        <mf-button
          :disabled="editable"
          color="primary"
          label="Corrigir Faturamento"
          class="my-4 ml-2"
          icon="settings"
          @click="fixBillingDialog = true"
        ></mf-button>
      </v-row>
    </v-expansion-panel-content>

    <change-dialog :dialog.sync="changesDialog" :message.sync="changesMessage" @proceed="saveFirstLoad" />
    <base-dialog v-model="fixBillingDialog" persistent max-width="800">
      <v-row v-if="loading">
        <v-col>
          <mf-loading-content :loading="true"></mf-loading-content>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="mt-5">
        <v-col class="headline text-center" cols="12">
          <span> <v-icon class="mb-2">mdi-cog</v-icon> Correção do Faturamento </span>
        </v-col>
        <v-col class="mt-5 text-center" cols="12">
          <span> As datas utilizadas serão as mesmas configuradas no "<strong>first_load</strong>" </span>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <span class="mx-5">Insira abaixo o motivo da correção (opcional):</span>
              <mf-text-area-input v-model="changesMessage" class="mx-4 mt-2" label="Motivo" />
            </v-col>
            <v-col class="mt-n6">
              <v-list class="mx-6 pt-0 mb-4">
                <v-list-item-group v-for="step in fixBillingSteps" :key="step.index">- {{ step }};</v-list-item-group>
              </v-list>
            </v-col>
            <v-col class="mt-n6" cols="12">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-row>
                    <v-col class="ml-6" cols="auto">
                      <mf-button color="error" icon="restart_alt" label="Cancelar" @click="fixBillingDialog = false"></mf-button>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row justify="end">
                    <v-col class="mr-6" cols="auto">
                      <mf-button label="Prosseguir" icon="error" @click="fixBilling"></mf-button>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </base-dialog>
  </v-expansion-panel>
</template>

<script>
import { MUTATION_EDIT_FIRST_LOAD, MUTATE_FIX_BILLING } from '@/modules/accounts/graphql'
import { DateTime } from 'luxon'

export default {
  components: {
    DatePicker: () => import('@/components/calendar/DatePicker'),
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    ChangeDialog: () => import('@/components/base/ChangeDialog'),
    BaseDialog: () => import('@/components/atomic-components/atoms/BaseDialog.vue')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    importConfiguration: {
      type: Object,
      default: () => ({})
    },
    db_name: {
      type: String,
      default: ''
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    integrationType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    editable: false,
    loading: false,
    saving: false,
    fixBillingDialog: false,
    changesDialog: false,
    changesMessage: '',
    valid: false,
    fixBillingSteps: [
      'Você será redirecionado para dag de "first_load" do cliente',
      'Ligue a dag, caso a mesma esteja desligada',
      'Aguarde as tasks respectivas às datas serem carregadas (aprox. 3 min)'
    ]
  }),
  computed: {
    maxFirstLoadEndDate() {
      const startDate = DateTime.fromISO(this.importConfiguration.first_load.start_date)
      return startDate.plus({ months: 1 }).toISO()
    },
    scales() {
      return [
        { text: 'Diario', value: 'days' },
        { text: 'Mensal', value: 'months' },
        { text: 'Semanal', value: 'weeks' }
      ]
    }
  },
  methods: {
    validateDate() {
      let start_date = DateTime.fromISO(this.importConfiguration.first_load.start_date)
      let end_date = DateTime.fromISO(this.importConfiguration.first_load.end_date)
      return !(start_date > end_date) || 'A data de início não pode ser maior que a data final.'
    },
    cancel() {
      this.editable = false
      this.$emit('cancel')
    },
    async saveFirstLoad() {
      const confirmation = await this.$confirm({
        title: 'Prosseguir com First Load',
        message: 'Deseja continuar com a ação?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      const variables = {
        id: this.accountId,
        selectedScale: this.importConfiguration.selectedScale,
        scaleAmount: +this.importConfiguration.scaleAmount,
        startDate: this.importConfiguration.first_load.start_date,
        endDate: this.importConfiguration.first_load.end_date,
        parallelism: +this.importConfiguration.first_load.parallelism
      }
      this.$setLogMessage(this.changesMessage)
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_FIRST_LOAD,
          variables
        })
        this.$alert({
          alert_message: 'Alterações realizadas com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.cancel()
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao editar First Load',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async fixBilling() {
      this.$setLogMessage(this.changesMessage)
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATE_FIX_BILLING,
          variables: {
            id: this.accountId,
            integration: this.integrationType
          }
        })
        this.$alert({
          alert_message: 'Reprocessamento configurado com  sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.cancel()
        this.openFirstLoadDag(this.db_name)
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao reprocessar faturamento',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    openFirstLoadDag(db_name) {
      if (db_name === '1111_franchise_0001_smart') return this.$snackbar({ message: 'Dag de first_load não encontrada', snackbarColor: '#F44336' })
      return window.open(`https://airflow-cluster.mercafacil.com/tree?dag_id=dag_${db_name}_first_load`, '_blank')
    },
    saveManager() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      if (this.isDeploying) return this.saveFirstLoad()
      this.changesDialog = true
    }
  }
}
</script>
